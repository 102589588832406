import * as React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../../components/seo';
import Default from '../../../layouts/default';
import { commonComponentHelper } from 'core/pageHelpers/commonComponentHelper';

const SubProcessorPage: React.FC<{ data: any }> = ({ data }) => {
  const subProcessorData: GatsbyTypes.ContentfulPage = data.subProcessor;
  const subProcessorPage = subProcessorData.edges[0].node;
  const seo = subProcessorPage.seo;
  const blocks = Object.values(commonComponentHelper(subProcessorPage.pageBlocks));

  return (
    <Default>
      <SEO data={seo} />
      {blocks.map((block) => block)}
    </Default>
  );
};

export default SubProcessorPage;

export const pageQuery = graphql`
  {
    subProcessor: allContentfulPage(filter: { slug: { eq: "legal/sub-processors" } }) {
      edges {
        node {
          seo {
            ...ContentfulSeo
          }
          ...defaultFields
        }
      }
    }
  }
`;
